$font-ligh: #fff;
$font-grey: #a2a2a2;
$font-dark: #000;

$background-ligh: #fff;
$background-grey-ligh:darkslategray;

$border-grey: #dbdbdb;
$border-dark: #000;

$action: #0095f6;
$danger: #ed4956;