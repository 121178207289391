@import "../../scss/index.scss";







.login{

 

.auth {
  margin-top: 0px;
   display: flex !important;
   align-items: center ;    
   justify-content: center;
   flex-direction: column;
   height: 90vh;
   
   
   img{
    width: 250px;
   }

   .container-form{
        width: 400px;
        background-color:ghostwhite;
        padding: 20px 50px;
        border: 1px solid whitesmoke;
        margin-top: 10px ;
        border-radius: 5px;
   }

   .change-form{
    margin-top: 10px;
    width: 400px;
    background-color: aliceblue;
    padding: 20px 50px;
    border: 1px solid whitesmoke;
    text-align: center;
    border-radius: 5px;
    span {
        color: $action;
        font-weight: bold;
        margin-left: 10Px;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
   }
}
.miniatura {
  display: flex;
  flex-direction: row;
  
   .image {
   
    display: flex;
    
    
    width:11% !important;
    border: 1px solid gold;
   
   }
   


}



.conta{
  width: 400px;
  border-top: 1px solid whitesmoke;
  padding: 20px 50px;
  text-align: justify-all !important;
  margin-top: 20px ;
  text-align: center  !important;
  border-radius: 5px;
  
}



}

 

.photo{
  //display:grid;
  //height: 100% !important;
  //width: 100% ;
  background-size: cover ;
 // background-repeat: no-repeat;
  background-position: center;
  background-image:url("./../../asets/png/nico3.jpeg");
  //height: 200% !important;
  //border: 1px solid gold;
 // display: list-item;
  //align-self:center;
  margin-bottom: 20px;
  margin-left: 2% !important;
  margin-right:1% !important;
  //display: grid ;
  height: 100% !important;
  width: 95% !important;
  //background-size: cover;
  background-repeat: no-repeat;
  //background-position: center;
  border: 1px solid gold; 
  

}


.logo {
  //height: 6em;
  //width: 6em;
  margin-top : 0px;
  will-change: filter;
  transition: filter 300ms;
  //margin-right: 50% !important;
  //margin-left:  50% !important;
  background-color:lavenderblush;
  border-radius: 50%;
  align-self: center !important;
  //align-content: center !important;
//align-items: center !important;

 // border-collapse: separate;
  border: 3px solid aliceblue;
  border-style: dotted;
  
}
.logo:hover {
  filter: drop-shadow(0em 1em .5em gold);
  
}


@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 5em;
}

.read{
margin-top: 27em;
 color:lightcyan;
 text-shadow: gold 1px 0 10px;;
 margin-bottom: 50%;
}

.izq{
  
    
    
      width: 100px;
      height: 100px;

  

  
}

.instruc{
  background-color:darkslategray;
  text-shadow: gold 1px 0 10px;
  width: 100%;
  i.whatsapp{
    color:greenyellow;
  }
  i.handshake{
    color:goldenrod
  }
  i.search{
    color:tomato;
  }
  
  h2{
    font-size: 12px !important;
    color:lightcyan;
  }

}


