@import "./scss/index.scss";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-grey-ligh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.form 
  .btn-submit{
    width: 100%;
    background-color: $action;
    color: $font-ligh;

  }
  .ui.form {
    .btn-remove{
      width: 100%;
      background-color: $danger;
      color: $font-ligh;
  
    }
  
  .submit-error{
    color: $danger;
    text-align: center;
    margin-top: 30px 0 0 0;
  }
}

.ui.button.btn_action{
  background-color: $action !important ;
  color: $font-ligh !important;
  border-color : $action !important;

}

.ui.button.btn_danger{
  background-color: $danger !important ;
  color: $font-ligh !important;
  border-color : $danger !important;

}